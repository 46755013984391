import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {
  RHFTextField,
  FormProvider,
  FormInputGroup,
  Button,
  RHFSelectField,
  Spinner,
  RHFTextArea,
} from 'ui-library';
import {EColorVariant} from 'ui-library/_enum';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducer';
import {SUPPORT_CATEGORY_LIST} from '_constant';
import axiosInstance from 'utils/axios';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

interface IAddOrganizationUnitProps {
  closeModal: () => void;
}

const ContactSupportForm = ({closeModal}: IAddOrganizationUnitProps) => {
  const {auth} = useSelector((state: RootState) => state);

  const {t} = useTranslation();

  const defaultValues = {
    email: auth.user?.email,
    organization_name: auth?.user?.organization?.name,
    category: '',
    message: '',
  };

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required(t('yup_resolver.required.email')).email(),
        organization_name: Yup.string().required(
          t('yup_resolver.required.org_name')
        ),
        category: Yup.string().required(t('yup_resolver.required.category')),
        message: Yup.string().required(t('yup_resolver.required.message')),
      })
    ),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: {isSubmitting},
  } = methods;

  const onSubmit = async (values: any) => {
    try {
      const postData = {
        category: values.category,
        page_url: window.location.href,
        message: values.message,
      };
      const res = await axiosInstance.post('support', postData);

      toast.success(res.data.data.message);
      closeModal();
    } catch (error) {
      console.error('error:', error);
    }
  };

  return (
    <div className="h-full w-[560px]">
      <div className="p-2">
        <div className="border p-4">
          <div className="my-2">
            <h2 className="text-2xl font-extralight">
              {t('support.contact_support')}
            </h2>
          </div>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <FormInputGroup label={t('support.email')}>
              <RHFTextField
                readOnly
                name="email"
                placeholder={t('support.email')}
              />
            </FormInputGroup>
            <FormInputGroup label={t('support.organization_name')}>
              <RHFTextField
                readOnly
                name="organization_name"
                placeholder={t('support.organization_name')}
              />
            </FormInputGroup>
            <FormInputGroup label={t('support.category')}>
              <RHFSelectField options={SUPPORT_CATEGORY_LIST} name="category" />
            </FormInputGroup>
            <FormInputGroup label={t('support.message')}>
              <RHFTextArea name="message" placeholder={t('support.message')} />
            </FormInputGroup>
            <div className="w-1/2 mt-8 mb-2">
              {isSubmitting ? (
                <Button disabled={true} color={EColorVariant.PRIMARY}>
                  <span className="flex justify-center content-center">
                    <Spinner size="sm" />
                    <p>{t('support.submit_form')}</p>
                  </span>
                </Button>
              ) : (
                <Button
                  type="submit"
                  text={t('support.submit_form')}
                  color={EColorVariant.PRIMARY}
                />
              )}
            </div>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default ContactSupportForm;
