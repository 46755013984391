import {store} from 'redux/store';
import {ROLES, ROLES_ACCESS} from '_constant/roles';
import {RoleEnum} from '_enum';
import axiosInstance from './axios';
import {TUser} from '../_types/user';

export const getInitials = (str = '') => {
  if (str.trim() === '') return '';

  return str.replace(/ /g, '').slice(0, 2).toUpperCase();
};

export const hasRoles = (roles: RoleEnum[]) => {
  const auth = store.getState().auth;

  if (!auth.user || !auth.user?.role) return false;

  return roles.includes(auth.user.role);
};

export const hasAccess = (access: string) => {
  const auth = store.getState().auth;

  if (!auth.user || !auth.user?.role) return;
  const accessArr = access.split('.');

  return (
    ROLES_ACCESS &&
    ROLES_ACCESS[accessArr[0]] &&
    ROLES_ACCESS[accessArr[0]][accessArr[1]].includes(auth.user.role)
  );
};

export const getInvitableRoles = (role: RoleEnum | undefined) => {
  switch (role) {
    case RoleEnum.ADMIN: {
      return ROLES;
    }

    case RoleEnum.Executive: {
      return ROLES.filter(role => role.value !== RoleEnum.ADMIN);
    }

    case RoleEnum.PM: {
      return ROLES.filter(
        role => role.value !== RoleEnum.ADMIN && role.value !== RoleEnum.PM
      );
    }

    default: {
      return [];
    }
  }
};

export const getUserRoles = (exclude: RoleEnum[] = []) =>
  Object.values(RoleEnum).filter(role => !exclude.includes(role));

export const getOrgUsers = async (
  orgUnitsQuery: number[],
  roles: RoleEnum[] = []
) => {
  try {
    const res = await axiosInstance.get<{data: TUser[]}>('users', {
      params: {
        organization_units: orgUnitsQuery,
        roles: roles,
      },
    });

    return res.data.data;
  } catch (error) {
    console.error('error: ' + error);
  }
};

export const getExecutiveUsers = async (orgUnitsQuery: number[]) =>
  await getOrgUsers(
    orgUnitsQuery,
    getUserRoles([
      RoleEnum.Other,
      RoleEnum.CONSULTANT,
      RoleEnum.CONSULTANT,
      RoleEnum.NO_ACCESS,
    ])
  );
