import {ReactNode} from 'react';
import {RootState} from 'redux/rootReducer';
import {useSelector} from 'react-redux';
import ForbiddenPage from 'pages/403';

interface IRoleBasedGuardProps {
  roles?: string[];
  children: ReactNode;
}

export default function RoleBasedGuard({
  roles,
  children,
}: IRoleBasedGuardProps) {
  // Logic here to get current user role
  const {auth} = useSelector((state: RootState) => state);
  const currentRole = auth.user?.role;

  if (!roles || !roles.find(role => role === currentRole)) {
    return <ForbiddenPage />;
  }

  return <>{children}</>;
}
