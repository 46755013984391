import {Menu, Transition} from '@headlessui/react';
import UserInitials from '../../common/UserInitials';
import {Fragment, useState} from 'react';
import {
  ArrowRightOnRectangleIcon,
  BuildingOffice2Icon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import {classNames} from '../../utils/helper';
import {getInitials} from 'utils/user-utils';
import {NavLink} from 'react-router-dom';
import {dispatch} from 'redux/store';
import {logoutUser} from 'redux/reducers/auth';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducer';
import SwitchOrganization from './SwitchOrganization';
import {useTranslation} from 'react-i18next';
import SwitchLanguage from './SwitchLanguage';

const AccountDropdowns = [
  {
    title: 'header.view_profile', // translation.json
    icon: UserCircleIcon,
    active: false,
    href: '/profile',
  },
];

const AccountDropdownMenu = () => {
  const {auth} = useSelector((state: RootState) => state);

  const [isOrgSelectorOpen, setOrganizationSelectorOpen] = useState(false);

  const {t, i18n} = useTranslation();

  const onLogoutClick = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
    } catch (err) {
      /* empty */
    }
  };

  return (
    <>
      <SwitchOrganization
        isOpen={isOrgSelectorOpen}
        toggle={() => setOrganizationSelectorOpen(false)}
      />
      <Menu as="div" className="relative">
        <div>
          <Menu.Button
            className={
              'flex justify-center items-center max-w-xs items-center rounded-full text-sm focus:outline-none ' +
              'h-9 w-9 ' +
              'rounded-full hover:bg-opacity-20 hover:bg-primary-900 focus:bg-primary-900 focus:bg-opacity-20'
            }
          >
            <span className="sr-only">{t('header.open_user_menu')}</span>
            <UserInitials initials={getInitials(auth.user?.full_name)} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute right-0 z-10 mt-2 w-60 origin-top-right
            rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="px-4 py-3 gap-2 flex w-full border-b border-gray-300 items-center">
              <UserInitials
                initials={getInitials(auth.user?.full_name)}
                size="md"
              />
              <div>
                <h6 className="text-gray-700 mb-0 text-sm font-semibold">
                  {auth.user?.full_name}
                </h6>
                <p className="text-sm font-normal text-gray-700 break-all">
                  {auth.user?.email}
                </p>
              </div>
            </div>
            {AccountDropdowns.map((item, key) => (
              <NavLink
                key={key}
                to={item.href}
                className={classNames(
                  item?.active ? 'bg-gray-100' : '',
                  'flex hover:bg-gray-100 px-5 h-[42px] items-center font-medium text-sm text-gray-700'
                )}
              >
                <item.icon className="w-6 h-6 text-gray-700 mr-2" />
                <span>{t(item.title)}</span>
              </NavLink>
            ))}
            <a
              onClick={() => setOrganizationSelectorOpen(true)}
              className={classNames(
                'cursor-pointer flex hover:bg-gray-100 px-5 h-[42px] items-center font-medium text-sm text-gray-700'
              )}
            >
              <BuildingOffice2Icon className="w-6 h-6 text-gray-700 mr-2" />
              <span>{t('header.switch_organization')}</span>
            </a>
            <SwitchLanguage />
            {/* <a
              onClick={() =>
                i18n.changeLanguage(i18n.language === 'en' ? 'fi' : 'en')
              }
              className="cursor-pointer flex hover:bg-gray-100 px-5 h-[42px]
              items-center font-medium text-sm text-gray-700"
            >
              <div className="w-6 h-6 mr-2 flex items-center justify-center">
                {i18n.language === 'en' ? (
                  <img
                    src="/img/languages/english.png"
                    alt={t('languages.en')}
                  />
                ) : (
                  <img
                    src="/img/languages/finnish.png"
                    alt={t('languages.fi')}
                  />
                )}
              </div>
              <span>
                {i18n.language === 'en' ? t('languages.en') : t('languages.fi')}
              </span>
            </a> */}
            <a
              onClick={() => onLogoutClick()}
              className={classNames(
                'cursor-pointer flex hover:bg-gray-100 border-t \
                 border-gray-200 px-5 h-[42px] items-center font-medium text-sm text-gray-700'
              )}
            >
              <ArrowRightOnRectangleIcon className="w-6 h-6 text-gray-700 mr-2" />
              <span>{t('header.log_out')}</span>
            </a>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default AccountDropdownMenu;
