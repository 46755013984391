import {Menu} from '@headlessui/react';
import {classNames} from '../../../utils/helper';
import {PropsWithChildren} from 'react';
import {Link} from 'react-router-dom';

interface DropDownItemProps extends PropsWithChildren {
  asLink?: boolean;
  onClick?: () => void;
  href?: string;
}

export default function DropDownItem({
  children,
  onClick,
  href,
}: DropDownItemProps) {
  return (
    <Menu.Item>
      {({active}) => (
        <Link
          to={href || '#'}
          onClick={onClick}
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'block px-4 py-2 text-sm flex gap-x-2 items-center'
          )}
        >
          {children}
        </Link>
      )}
    </Menu.Item>
  );
}
