import NavItem from './NavItem';
import NavTitle from './NavTitle';
import {SIDE_BAR_ITEMS} from '_constant/sidebar';
import {RootState} from 'redux/rootReducer';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {TSideBarItem} from '_types/types';

const Sidebar = () => {
  const {auth} = useSelector((state: RootState) => state);

  const {t} = useTranslation();

  return (
    <div className="h-screen pt-[3px] bg-white border-r relative w-full px-4 overflow-auto pb-6">
      <div className="h-14 mt-3 flex items-center">
        <div className="inline-flex items-center">
          <h1 className="text-[24px] duration-300 font-semibold text-gray-600">
            {t('app_name')}
          </h1>
          <img
            className="w-auto h-8 ml-1"
            src="/img/amplon-logo.png"
            alt={t('app_name')}
          />
        </div>
      </div>
      <nav>
        <ul className="space-y-2">
          {SIDE_BAR_ITEMS.filter(
            (item: TSideBarItem) =>
              item.access?.includes(auth.user?.role) ||
              item.access === undefined
          ).map((item, index) =>
            item.type === 'Title' ? (
              <NavTitle
                to={item.to}
                key={index}
                text={item.title}
                IconElement={item.icon}
                end={item.end}
              />
            ) : (
              <NavItem
                to={item.to}
                key={index}
                text={item.title}
                IconElement={item.icon}
                end={item.end}
              />
            )
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
