import {ArrowPathRoundedSquareIcon} from '@heroicons/react/24/outline';
import {RadioInput} from 'ui-library';
import Modal from '../../components/Modal';
import {useEffect, useState} from 'react';
import {TOrganization} from '../../_types/types';
import axiosInstance from '../../utils/axios';
import {requestErrorHandler} from '../../utils/request-error-handler';
import {toast} from 'react-toastify';
import OverlayLoader from '../../common/OverlayLoader';
import UserInitials from '../../common/UserInitials';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/rootReducer';
import {getInitials} from '../../utils/user-utils';
import {useTranslation} from 'react-i18next';
import {
  getNewOrganizationHref,
  getNewOrganizationSSORedirectUrl,
} from '../../utils/helper';

interface SwitchOrganizationProps {
  isOpen: boolean;
  toggle: () => void;
}

export default function SwitchOrganization({
  isOpen,
  toggle,
}: SwitchOrganizationProps) {
  const {auth} = useSelector((state: RootState) => state);
  const [organizations, setOrganizations] = useState<TOrganization[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {t} = useTranslation();

  const getOrganizations = async () => {
    setIsLoading(true);
    try {
      const {data} = await axiosInstance.get<{data: TOrganization[]}>(
        'session/organizations'
      );

      setOrganizations(data.data);
    } catch (e) {
      const {errorMessages} = requestErrorHandler(e);

      toast.error(errorMessages[0]?.message);
      toggle();
    } finally {
      setIsLoading(false);
    }
  };

  type AuthorizeLogin = {
    authorize_url: string;
  };

  type LogoutResponse = {logout_state: string; logout: true};

  type SwitchOrganizationResponse =
    | AuthorizeLogin
    | LogoutResponse
    | {email: string};

  const switchOrganization = async (organization: TOrganization) => {
    setIsLoading(true);

    try {
      const {data} = await axiosInstance.post<{
        data: SwitchOrganizationResponse;
      }>(`session/organizations/${organization.id}`, {
        redirect_uri: getNewOrganizationSSORedirectUrl(organization.subdomain),
      });

      if ('authorize_url' in data.data) {
        window.location.href = data.data.authorize_url;
      } else if ('logout_state' in data.data) {
        const newHref = getNewOrganizationHref(organization.subdomain);

        window.location.href = `${newHref}/auth/login?state=${data.data.logout_state}`;
      } else {
        window.location.href = '/dashboard';
      }
    } catch (e) {
      const {errorMessages} = requestErrorHandler(e);

      toast.error(errorMessages[0]?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getOrganizations();
    }
  }, [isOpen]);

  return isLoading ? (
    <OverlayLoader />
  ) : (
    <Modal open={isOpen} setOpen={toggle}>
      <div className="w-[320px] -mt-5 pb-4">
        <div className="flex flex-col gap-y-2 mb-3">
          <div
            className="bg-primary-100 rounded-full items-center
            justify-center inline-flex w-[35px] h-[35px] text-primary-600"
          >
            <ArrowPathRoundedSquareIcon className="h-5 w-5 stroke-2" />
          </div>
          <h4 className="text-lg text-gray-700 font-semibold">
            {t('header.switch_organization')}
          </h4>
        </div>
        <div className="flex flex-col gap-y-3">
          {organizations.map(organization => (
            <label
              key={organization.id}
              className={`flex border items-center gap-x-3 justify-between rounded-md px-4 py-2.5 cursor-pointer ${
                auth.user?.organization?.id === organization.id &&
                'pointer-events-none border-primary-600 border-2'
              }`}
              onClick={async () => await switchOrganization(organization)}
            >
              <div className="flex gap-x-2 items-center">
                {organization.logo ? (
                  <img
                    alt="Logo"
                    className="w-[120px]"
                    src={organization.logo}
                  />
                ) : (
                  <UserInitials
                    size="md"
                    initials={getInitials(organization.name)}
                  />
                )}

                <div className="text-gray-700 font-semibold">
                  <p>{organization.name}</p>
                </div>
              </div>
              <RadioInput
                checked={auth.user?.organization?.id === organization.id}
              />
            </label>
          ))}
        </div>
      </div>
    </Modal>
  );
}
