import {PropsWithChildren} from 'react';
import {Menu} from '@headlessui/react';

interface DropDownMenuProps extends PropsWithChildren {
  className?: string;
}

export default function DropDownMenu({children, className}: DropDownMenuProps) {
  return (
    <Menu as="div" className={`relative inline-block text-left ${className}`}>
      {children}
    </Menu>
  );
}
