import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TModalInitState} from 'redux/types';

const initialState: TModalInitState = {
  modalName: '',
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalFormState: (
      state: TModalInitState,
      action: PayloadAction<TModalInitState>
    ) => {
      state.isEditable = action.payload.isEditable;
      state.modalName = action.payload.modalName;
      state.recordId = action.payload.recordId;
    },
    toggleModalView: (
      state: TModalInitState,
      action: PayloadAction<boolean>
    ) => {
      state.isEditable = action.payload;
    },
  },
});

export default slice.reducer;

export const {setModalFormState, toggleModalView} = slice.actions;
