import {ModalContext} from 'contexts/ModalProvider';
import {useContext, useEffect, useId, useMemo} from 'react';

export const useModal = (open: boolean) => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  const {openedModals, setOpenedModals} = context;

  const modalId = useId();

  useEffect(() => {
    if (open) {
      setOpenedModals(prevModals => [...prevModals, modalId]);
    } else {
      setOpenedModals(prevModals => {
        if (prevModals.includes(modalId)) {
          return prevModals.filter(m => m !== modalId);
        } else {
          return prevModals;
        }
      });
    }
  }, [open, modalId]);

  const isLastOpenedModal = useMemo(
    () => openedModals[openedModals.length - 1] === modalId,
    [openedModals, modalId]
  );

  return {isLastOpenedModal};
};
