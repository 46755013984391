import React from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

interface INavItemProps {
  text: string;
  active?: boolean;
  to: string;
  end?: boolean;
  IconElement: React.ElementType;
}

export default function NavItem({to, text, IconElement, end}: INavItemProps) {
  const {t} = useTranslation();

  return (
    <li>
      <NavLink
        className={({isActive}) =>
          'group flex p-2 gap-x-3 items-center text-[14px] font-medium ' +
          'rounded-lg hover:bg-primary-700 hover:bg-opacity-10 hover:text-primary-700 ' +
          (isActive
            ? 'bg-primary-700 bg-opacity-10 text-primary-700'
            : 'text-gray-700')
        }
        end={end}
        to={to}
      >
        <IconElement className="w-4 h-4 block float-left text-gray-700 group-hover:text-primary-700" />
        <span className="flex whitespace-nowrap duration-200 origin-left flex-nowrap overflow-hidden">
          {t(text)}
        </span>
      </NavLink>
    </li>
  );
}
