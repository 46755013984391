import React from 'react';
import {useTranslation} from 'react-i18next';

interface INavItemProps {
  text: string;
  active?: boolean;
  to: string;
  end?: boolean;
  IconElement: React.ElementType;
}

export default function NavTitle({text}: INavItemProps) {
  const {t} = useTranslation();

  return (
    <li>
      <span
        className={
          'flex whitespace-nowrap font-semibold text-[13px] text-gray-700 ' +
          'mt-4 uppercase duration-200 origin-left flex-nowrap overflow-hidden'
        }
      >
        {t(text)}
      </span>
    </li>
  );
}
