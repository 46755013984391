import {
  Cog8ToothIcon,
  DocumentChartBarIcon,
  ChartBarSquareIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  HomeIcon,
  ChartPieIcon,
  DocumentMagnifyingGlassIcon,
  UsersIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/react/24/outline';
import {ROLES_ACCESS} from '_constant/roles';
import {TSideBarItem} from '_types/types';

export const SIDE_BAR_ITEMS: TSideBarItem[] = [
  {
    type: 'Title',
    title: 'sidebar.main.title', // translation.json
    icon: HomeIcon,
    to: '/',
    end: true,
    access: [
      ...(ROLES_ACCESS.DASHBOARD?.view || []),
      ...(ROLES_ACCESS.XMATRIX.view || []),
      ...(ROLES_ACCESS.PORTFOLIO_MANAGMENT.view || []),
    ],
  },
  {
    type: 'Link',
    title: 'sidebar.main.overview',
    icon: HomeIcon,
    to: '/',
    end: true,
    access: ROLES_ACCESS.DASHBOARD.view,
  },
  {
    type: 'Link',
    title: 'sidebar.main.xmatrix',
    icon: Squares2X2Icon,
    to: '/xmatrix',
    access: ROLES_ACCESS.XMATRIX.view,
  },
  {
    type: 'Link',
    title: 'sidebar.main.portfolio_management',
    icon: Square3Stack3DIcon,
    to: '/portfolio-management',
    access: ROLES_ACCESS.PORTFOLIO_MANAGMENT.view,
  },
  {
    type: 'Title',
    title: 'sidebar.dashboard.title',
    icon: ChartBarSquareIcon,
    to: '/',
    access: [
      ...(ROLES_ACCESS.STRATEGY_MAKING?.view || []),
      ...(ROLES_ACCESS.EXECUTION_REPORTS.view || []),
      ...(ROLES_ACCESS.PROJECT_MANAGEMENT.view || []),
      ...(ROLES_ACCESS.ALL_TASKS.view || []),
    ],
  },
  {
    type: 'Link',
    title: 'sidebar.dashboard.strategy_making',
    icon: ChartPieIcon,
    to: '/strategy-making',
    access: ROLES_ACCESS.STRATEGY_MAKING.view,
  },
  {
    type: 'Link',
    title: 'sidebar.dashboard.exec_reports',
    icon: ChartBarSquareIcon,
    to: '/execution-reports',
    access: ROLES_ACCESS.EXECUTION_REPORTS.view,
  },
  {
    type: 'Link',
    title: 'sidebar.dashboard.my_tasks',
    icon: DocumentChartBarIcon,
    to: '/project-management',
    access: ROLES_ACCESS.PROJECT_MANAGEMENT.view,
  },
  {
    type: 'Link',
    title: 'sidebar.dashboard.all_tasks',
    icon: DocumentMagnifyingGlassIcon,
    to: '/all-tasks',
    access: ROLES_ACCESS.ALL_TASKS.view,
  },
  {
    type: 'Title',
    title: 'sidebar.others.title',
    icon: ChartBarSquareIcon,
    to: '/reports',
    access: [
      ...(ROLES_ACCESS.USER?.view || []),
      ...(ROLES_ACCESS.SETTING.view || []),
    ],
  },

  {
    type: 'Link',
    title: 'sidebar.others.users',
    icon: UsersIcon,
    to: '/users',
    access: ROLES_ACCESS.USER.view,
  },
  {
    type: 'Link',
    title: 'sidebar.others.settings',
    icon: Cog8ToothIcon,
    to: '/settings',
    access: ROLES_ACCESS.SETTING.view,
  },
  {
    type: 'Link',
    title: 'sidebar.others.integrations',
    icon: AdjustmentsHorizontalIcon,
    to: '/integrations',
    access: ROLES_ACCESS.INTEGRATIONS.view,
  },
];
