import React from 'react';
import ReactDOM from 'react-dom/client';
import 'ui-library/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Provider as ReduxProvider} from 'react-redux';
import {store} from './redux/store';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import {ModalProvider} from 'contexts/ModalProvider';
import './services/i18n';
import LoadingScreen from 'components/LoadingScreen';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new BrowserTracing({
      // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      //   React.useEffect,
      //   useLocation,
      //   useNavigationType,
      //   createRoutesFromChildren,
      //   matchRoutes
      // ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Number(process.env.SENTRY_TRACE_SAMPLE_RATE) || 0.2,
});

// const sentryCreateBrowserRouter =
//   Sentry.wrapCreateBrowserRouter(createBrowserRouter);
//
// const Router = sentryCreateBrowserRouter([]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<LoadingScreen />}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <ModalProvider>
            <App />
          </ModalProvider>
        </BrowserRouter>
      </ReduxProvider>
    </React.Suspense>
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
