import Modal from 'components/Modal';
import {useState} from 'react';
import ContactSupportForm from './components/ContactSupportForm';
import './support.css';
import {useTranslation} from 'react-i18next';

const Support = () => {
  const [openForm, setOpenForm] = useState(false);

  const {t} = useTranslation();

  return (
    <>
      <div className="support-container">
        <button
          type="button"
          onClick={() => setOpenForm(true)}
          className="btn btn-primary support-btn"
        >
          <span>{t('support.contact_support')}</span>
        </button>
      </div>
      <Modal open={openForm} setOpen={setOpenForm}>
        <ContactSupportForm closeModal={() => setOpenForm(false)} />
      </Modal>
    </>
  );
};

export default Support;
