import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import {TOrgUnit} from '_types/types';

export const getOrgUnits = createAsyncThunk('org_units/get_units', async () => {
  const {data: body, ...resp} = await axiosInstance.get<{data: TOrgUnit[]}>(
    'session/organization-units'
  );
  const sortedUnits = body.data.sort((a, b) => {
    if (a.unit_name.toLowerCase() < b.unit_name.toLowerCase()) {
      return -1;
    }
    if (a.unit_name.toLowerCase() > b.unit_name.toLowerCase()) {
      return 1;
    }

    return 0;
  });
  let resData: TOrgUnit[] = [];

  sortedUnits
    .filter(unit => !unit.parent_organization_unit_id)
    .forEach(unit => {
      resData.push(unit);
      const childrenData = getSortedChildren(unit, sortedUnits);

      resData = [...resData, ...childrenData];
    });

  return {
    data: {data: resData},
    ...resp,
  };
});

export const changeActiveOrgUnit = createAsyncThunk(
  'org_units/change_active_unit',
  async (id: number) =>
    await axiosInstance.post(`session/organization-units/${id}`)
);

const getSortedChildren = (unit: TOrgUnit, sortedUnits: TOrgUnit[]) => {
  let retData: TOrgUnit[] = [];
  const filteredRec = sortedUnits.filter(
    item => unit.id === item.parent_organization_unit_id
  );

  if (filteredRec.length === 0) {
    return [];
  }
  filteredRec.forEach(childUnit => {
    retData.push(childUnit);
    const childrenData = getSortedChildren(childUnit, sortedUnits);

    retData = [...retData, ...childrenData];
  });

  return retData;
};
