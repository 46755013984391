// import React from 'react';
import {Menu} from '@headlessui/react';
import DropDownItem from 'common/DropDown/DropDownItem';
import DropDownListItem from 'common/DropDown/DropDownListItem';
import DropDownMenu from 'common/DropDown/DropDownMenu';

import {useTranslation} from 'react-i18next';

const SwitchLanguage = () => {
  const {i18n, t} = useTranslation();

  const options = [
    {key: 'en', value: 'languages.en'},
    {key: 'es', value: 'languages.es'},
    {key: 'fi', value: 'languages.fi'},
    {key: 'lt', value: 'languages.lt'},
  ];

  return (
    <DropDownMenu className="flex items-center justify-center">
      <Menu.Button
        className="w-full cursor-pointer flex hover:bg-gray-100 px-5 h-[42px]
              items-center font-medium text-sm text-gray-700"
      >
        <div className="w-6 h-6 mr-2 flex items-center justify-center">
          <img
            src={`/img/languages/${
              options.find(o => o.key === i18n.language)?.key || 'en'
            }.png`}
            alt={t(
              options.find(o => o.key === i18n.language)?.value ||
                'languages.en'
            )}
          />
        </div>
        <span>
          {t(
            options.find(o => o.key === i18n.language)?.value || 'languages.en'
          )}
        </span>
      </Menu.Button>
      <DropDownListItem className="relative">
        {options.map(x => (
          <DropDownItem key={x.key} onClick={() => i18n.changeLanguage(x.key)}>
            <a
              className="cursor-pointer flex hover:bg-gray-100 px-5 h-[42px]
              items-center font-medium text-sm text-gray-700"
            >
              <div className="w-6 h-6 mr-2 flex items-center justify-center">
                <img src={`/img/languages/${x.key}.png`} alt={t(x.value)} />
              </div>
              <span>{t(x.value)}</span>
            </a>
          </DropDownItem>
        ))}
      </DropDownListItem>
    </DropDownMenu>
  );
};

export default SwitchLanguage;
