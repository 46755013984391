import Support from 'components/Support';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {RootState} from 'redux/rootReducer';
import {LogoLoader} from 'ui-library';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

export default function AuthenticatedLayout() {
  const {orgUnits} = useSelector((state: RootState) => state);
  const [sidebarOpen, toggleSidebar] = useState<boolean>(true);
  const [mobileToggle, setMobileToggle] = useState<boolean>(false);

  return (
    <>
      <div className="max-width-[1920px] h-screen flex flex-row overflow-hidden bg-[#F4F5F7]">
        <div
          className={`transform sidebar-basis w-64 duration-300 
          overflow-hidden h-screen fixed z-20 top-0 left-0 -translate-x-64 ${
            sidebarOpen
              ? 'lg:translate-x-0 '
              : '-translate-x-64 duration-200 ' +
                `${mobileToggle && 'translate-x-0 lg:-translate-x-64 '}`
          }`}
        >
          <Sidebar />
        </div>
        <div
          onClick={() => {
            toggleSidebar(!sidebarOpen);
            setMobileToggle(!mobileToggle);
          }}
          className={
            'top-0 left-0 h-screen w-full z-10 fixed bg-black bg-opacity-20 cursor-pointer ' +
            `${mobileToggle ? 'lg:hidden' : 'hidden'}`
          }
        />
        <div
          className={`flex flex-col flex-1 overflow-hidden duration-300 ${
            sidebarOpen ? 'lg:pl-64' : 'pl-0'
          } `}
        >
          <Header
            toggleSidebar={() => {
              toggleSidebar(!sidebarOpen);
              setMobileToggle(!mobileToggle);
            }}
          />

          <main className="flex-1 overflow-x-hidden overflow-y-auto">
            <div className="lg:container-2xl p-2 md:p-6 m-auto">
              {orgUnits.loading ? <LogoLoader /> : <Outlet />}
            </div>
          </main>
        </div>
      </div>
      <Support />
    </>
  );
}
