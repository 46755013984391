import axios from 'axios';
import {dispatch} from 'redux/store';
import {memoizedRefreshToken} from './refresh-token';
import {actions as authActions} from '../redux/slices/auth';
import * as Sentry from '@sentry/react';
import {toast} from 'react-toastify';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(async config => config);

const toastMsgs = [400, 422, 403];

axiosInstance.interceptors.response.use(
  async response => response,
  async error => {
    const originalRequest = error?.config || {};

    if (error?.response?.status >= 500) {
      Sentry.captureException(error);
    }
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const result = await memoizedRefreshToken();

      if (result && originalRequest.url !== 'logout') {
        return axios(originalRequest);
      } else {
        dispatch(authActions.removeAuth());
      }
    }
    if (toastMsgs.includes(error?.response?.status)) {
      toast.error(error.response.data.message);
    }

    return Promise.reject(error);
  }
);
export default axiosInstance;
