import {useEffect} from 'react';

export const useEscapeClose = (close: (() => void) | null | undefined) => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && close) {
        close();
      }
    };

    if (close) {
      window.addEventListener('keydown', handler);
    }

    return () => {
      if (close) {
        window.removeEventListener('keydown', handler);
      }
    };
  }, [close]);
};
