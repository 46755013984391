import axiosInstance from 'utils/axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {getOrganizationSettings} from '../../apis/organization';
import {IXMatrixSettings} from '../../interfaces/organization';
import {OrganizationSettingsType} from '../../_enum/organization';

export const getData = createAsyncThunk(
  'x-matrix/get_data',
  async () => await axiosInstance.get('x-matrix')
);

export const getXMatrixSettings = createAsyncThunk(
  'x-matrix/settings',
  async () => {
    const {data} = await getOrganizationSettings<IXMatrixSettings>(
      OrganizationSettingsType.X_MATRIX_LABELS
    );

    return data.data;
  }
);

export const setCoRelation = createAsyncThunk(
  'x-matrix/set_co_relation',
  async () => true
);
