import {EActivityStatus, EPROJECT_STATUS} from '_enum';
import {TProjectTask} from '../_types/project';

export const DISPLAYED_TRAFFIC_LIGHT_COLORS = {
  TRAFFIC_LIGHT_GREEN: 'green',
  TRAFFIC_LIGHT_YELLOW: 'orange',
  TRAFFIC_LIGHT_RED: 'red',
};

export const LIGHT_OPTIONS = [
  {label: 'constants.project.color.green', value: 'G'}, // translation.json
  {label: 'constants.project.color.yellow', value: 'Y'},
  {label: 'constants.project.color.red', value: 'R'},
];

export const PROJECT_STATUS = [
  {
    label: 'constants.project.status.active', // translation.json
    value: EPROJECT_STATUS.ACTIVE,
  },
  {
    label: 'constants.project.status.backlog',
    value: EPROJECT_STATUS.BACKLOG,
  },
  {
    label: 'constants.project.status.finished',
    value: EPROJECT_STATUS.FINISHED,
  },
  {
    label: 'constants.project.status.archived',
    value: EPROJECT_STATUS.ARCHIVED,
  },
];

export const OBJECTIVE_STATUS = [
  {
    label: 'constants.project.status.active', // translation.json
    value: EPROJECT_STATUS.ACTIVE,
  },
  {
    label: 'constants.project.status.backlog',
    value: EPROJECT_STATUS.BACKLOG,
  },
  {
    label: 'constants.project.status.finished',
    value: EPROJECT_STATUS.FINISHED,
  },
];

export const PROJECT_PRIORITY = [
  {
    label: 'constants.project.priority.high', // translation.json
    value: 'H',
  },
  {
    label: 'constants.project.priority.low',
    value: 'L',
  },
];
export const PROJECT_PARAMETERS = [
  {
    label: 'constants.project.parameters.time_driven', // translation.json
    value: 'T',
  },
  {
    label: 'constants.project.parameters.other',
    value: 'O',
  },
  {
    label: 'constants.project.parameters.cost_driven',
    value: 'C',
  },
  {
    label: 'constants.project.parameters.equally_driven',
    value: 'E',
  },
];

export const PROJECT_RISK_SEVERITIES = [
  {
    label: 'constants.project.risk_severities.no_impact', // translation.json
    value: 1,
  },
  {
    label: 'constants.project.risk_severities.small',
    value: 2,
  },
  {
    label: 'constants.project.risk_severities.average',
    value: 3,
  },
  {
    label: 'constants.project.risk_severities.substantial',
    value: 4,
  },
  {
    label: 'constants.project.risk_severities.disaster',
    value: 5,
  },
];

export const PROJECT_RISK_PROBABILITIES = [
  {
    label: 'constants.project.risk_probabilities.unlikely', // translation.json
    value: 1,
  },
  {
    label: 'constants.project.risk_probabilities.probably_not',
    value: 2,
  },
  {
    label: 'constants.project.risk_probabilities.even',
    value: 3,
  },
  {
    label: 'constants.project.risk_probabilities.probable',
    value: 4,
  },
  {
    label: 'constants.project.risk_probabilities.almost_certain',
    value: 5,
  },
];

export const MOCK_ACTIVITY = {
  title: '',
  project_end_date: '',
  owner: '',
  description: '',
  status: EActivityStatus.NOT_DONE,
} as TProjectTask;
