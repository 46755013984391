import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import {TUser} from '../../_types/user';
import {stripTrailingSlash} from '../../utils/helper';

export type LogInPostData = {
  email: string;
  password: string;
};

export type ResetPasswordPostData = {
  password: string;
  password_confirmation: string;
  token: string | null;
};

export type ForgotPasswordPostData = {
  email: string;
};

export const checkSession = createAsyncThunk<any>(
  'auth/checkSession',
  async () => {
    const response = await axiosInstance.get('session');
    const audience = response.headers['x-audience'];

    if (audience) {
      try {
        const audienceUrl = new URL(`https://${audience}`);
        const currentUrl = new URL(window.location.href);

        if (currentUrl.hostname !== audienceUrl.hostname) {
          currentUrl.hostname = audienceUrl.hostname;
          window.location.href = stripTrailingSlash(currentUrl.href);
        }
      } catch (e) {
        console.debug(e);
      }
    }

    return response;
  }
);

export const loginUser = createAsyncThunk(
  'auth/login',
  async (data: LogInPostData, {rejectWithValue}) => {
    try {
      return await axiosInstance.post<{data: {user: TUser}}>('login', data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk('auth/logout', async () => {
  await axiosInstance.post('logout');

  return true;
});
