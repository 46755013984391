import {OrganizationSettingsType} from '../_enum/organization';
import axios from '../utils/axios';

export async function getOrganizationSettings<T = unknown>(
  settingsType: OrganizationSettingsType
) {
  return axios.get<{data: T | null}>(
    `organization/settings?setting_type=${settingsType}`
  );
}

export async function getOrganization<T = unknown>(id?: number) {
  if (!id) return {data: null};

  return axios.get<{data: T | null}>(`organization-units/${id}`);
}

export async function getOrganizationUsers<T = unknown>(id?: number) {
  if (!id) return {data: null};

  return axios.get<{data: T | null}>(`organization-units/${id}/users`);
}
