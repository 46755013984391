export enum EPROJECT_STATUS {
  ACTIVE = 50,
  BACKLOG = 1,
  FINISHED = 70,
  ARCHIVED = 99,
}

export enum EOBJECTIVE_STATUS {
  ACTIVE = 50,
  BACKLOG = 1,
  FINISHED = 70,
}

export enum ProjectTypeEnum {
  PROGRAM = 'PROGRAM',
  PROJECT = 'PROJECT',
}

export enum ECommentType {
  PROJECT_DAILY_MANAGEMENT = 'PROJECT_DAILY_MANAGEMENT',
  PM_REPORT = 'PM_REPORT',
}

export enum EActivityStatus {
  DONE = 70,
  NOT_DONE = 1,
}
