import {LazyExoticComponent, Suspense, lazy} from 'react';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';
import AuthLayout from '../layouts/AuthLayout';
import {useRoutes} from 'react-router-dom';
import AuthGuard from '../_guards/AuthGuard';
import LoadingScreen from 'components/LoadingScreen';
import {LogoLoader} from 'ui-library';
import RoleBasedGuard from '_guards/RoleBasedGuard';
import {ROLES_ACCESS} from '_constant/roles';

const Loadable =
  (Component: LazyExoticComponent<() => JSX.Element>) =>
  (props: {[key: string]: unknown}) =>
    (
      <Suspense fallback={<LogoLoader />}>
        <Component {...props} />
      </Suspense>
    );

export default function Router() {
  const element = useRoutes([
    {
      path: 'auth',
      element: <AuthLayout />,
      children: [
        {path: 'login', element: <Login />},
        {path: 'forgot-password', element: <ForgotPassword />},
        // TODO: check if could remove it completely
        {path: 'reset-password', element: <ResetPassword />},
        {path: 'reset-password/:token', element: <ResetPassword />},
        {path: 'callback', element: <AuthCallback />},
      ],
    },
    {
      path: 'splash',
      element: <LoadingScreen />,
    },
    {
      path: 'invites',
      element: <AuthLayout />,
      children: [{path: ':token', element: <Invites />}],
    },
    {
      path: '',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <Dashboard />}],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.DASHBOARD.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: 'xmatrix',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.XMATRIX.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <XMatrix />,
        },
      ],
    },
    {
      path: 'xmatrix',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.XMATRIX.edit}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'edit',
          element: <EditXMatrix />,
        },
      ],
    },
    {
      path: 'strategy-making',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.STRATEGY_MAKING.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <StrategyMaking />,
        },
        {
          path: ':tab',
          element: <StrategyMaking />,
        },
      ],
    },
    {
      path: 'execution-reports',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.EXECUTION_REPORTS.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <ExecutionReports />,
        },
        {
          path: ':tab',
          element: <ExecutionReports />,
        },
      ],
    },
    {
      path: 'portfolio-management',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.PORTFOLIO_MANAGMENT.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <PortfolioManagement />,
        },
        {
          path: ':tab',
          element: <PortfolioManagement />,
        },
      ],
    },
    {
      path: 'project-management',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.PROJECT_MANAGEMENT.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <ProjectManagement />,
        },
        {
          path: ':tab',
          element: <ProjectManagement />,
        },
      ],
    },
    {
      path: 'all-tasks',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.ALL_TASKS.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <AllTasks />,
        },
        {
          path: ':tab',
          element: <AllTasks />,
        },
      ],
    },
    {
      path: 'users',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.USER.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Users />,
        },
        {
          path: ':tab',
          element: <Users />,
        },
      ],
    },
    {
      path: 'financials',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.PROJECT_CHARTER.edit}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: ':id',
          element: <Financials />,
        },
      ],
    },
    {
      path: 'project-charter',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.PROJECT_CHARTER.add}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'add',
          element: <AddProject />,
        },
      ],
    },
    {
      path: 'project-charter',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.PROJECT_CHARTER.edit}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'edit/:id',
          element: <EditProject />,
        },
      ],
    },
    {
      path: 'project-charter',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.PROJECT_CHARTER.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'view/:id',
          element: <ViewProject />,
        },
      ],
    },
    {
      path: 'profile',
      element: (
        <AuthGuard>
          <AuthenticatedLayout />
        </AuthGuard>
      ),
      children: [{path: '', element: <UpdateProfile />}],
    },
    {
      path: 'settings',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.SETTING.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Settings />,
        },
        {
          path: 'organization-units/:id',
          element: <UpdateOrganizationUnit />,
        },
        {
          path: ':tab',
          element: <Settings />,
        },
      ],
    },
    {
      path: 'settings',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.SETTING.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Settings />,
        },
        {
          path: 'organization-units/:id',
          element: <UpdateOrganizationUnit />,
        },
      ],
    },
    {
      path: 'integrations',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={ROLES_ACCESS.INTEGRATIONS.view}>
            <AuthenticatedLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <IntegrationsPage />,
        },
        {
          path: ':tab',
          element: <IntegrationsPage />,
        },
      ],
    },
    {
      path: '',
      element: <AuthLayout />,
      children: [{path: '*', element: <NotFound />}],
    },
  ]);

  return element;
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ForgotPassword = Loadable(
  lazy(() => import('../pages/auth/ForgotPassword'))
);
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword'))
);
const AuthCallback = Loadable(lazy(() => import('../pages/auth/AuthCallback')));

const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const Invites = Loadable(lazy(() => import('../pages/invites')));

//UpdateOrganizationUnit
const UpdateOrganizationUnit = Loadable(
  lazy(() => import('../modules/organization-unit/UpdateOrganizationUnit'))
);

//Project
const AddProject = Loadable(
  lazy(() => import('../pages/project-charter/AddProject'))
);
const EditProject = Loadable(
  lazy(() => import('../pages/project-charter/EditProject'))
);
const ViewProject = Loadable(
  lazy(() => import('../pages/project-charter/ViewProject'))
);

const XMatrix = Loadable(lazy(() => import('../pages/xmatrix')));
const NotFound = Loadable(lazy(() => import('../pages/404')));
const EditXMatrix = Loadable(
  lazy(() => import('../pages/xmatrix/EditXMatrix'))
);
const StrategyMaking = Loadable(lazy(() => import('../pages/strategy-making')));
const Users = Loadable(lazy(() => import('../pages/users')));
const ExecutionReports = Loadable(
  lazy(() => import('../pages/execution-reports'))
);
const PortfolioManagement = Loadable(
  lazy(() => import('../pages/portfolio-management'))
);
const ProjectManagement = Loadable(
  lazy(() => import('../pages/project-management'))
);
const AllTasks = Loadable(lazy(() => import('../pages/all-tasks')));
const UpdateProfile = Loadable(
  lazy(() => import('../pages/profile/UpdateProfile'))
);

//Settings
const Settings = Loadable(lazy(() => import('../pages/settings')));

const IntegrationsPage = Loadable(lazy(() => import('../pages/integrations')));

const Financials = Loadable(lazy(() => import('../pages/financials')));
