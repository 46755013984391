import {Spinner} from 'ui-library';

interface IOverlayLoaderProps {
  className?: string;
}

const OverlayLoader = ({className}: IOverlayLoaderProps) => (
  <div
    className={`flex justify-center items-center fixed 
    left-0 top-0 w-screen h-screen bg-black bg-opacity-50 z-[99999] ${
      className || ''
    }`}
  >
    <Spinner size="xl" color="text-primary-900" />
  </div>
);

export default OverlayLoader;
