import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TBreadcrumbInitState} from 'redux/types';

const initialState: TBreadcrumbInitState = {
  items: [],
};

const slice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumb: (
      state: TBreadcrumbInitState,
      action: PayloadAction<any>
    ) => {
      state.items = action.payload;
    },
  },
});

export default slice.reducer;

export const {setBreadcrumb} = slice.actions;
