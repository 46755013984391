import mem from 'mem';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

const refreshTokenFn = async () => {
  try {
    await axiosInstance.post('/refresh-token', {});

    return true;
  } catch (e) {
    return false;
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});
