import LoadingScreen from 'components/LoadingScreen';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Outlet} from 'react-router-dom';
import {checkSession} from 'redux/reducers/auth';
import {RootState} from 'redux/rootReducer';
import {dispatch} from '../redux/store';

export default function AuthLayout() {
  const {isAuthenticated, isInitialized} = useSelector(
    (state: RootState) => state.auth
  );
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);

  const getSession = async () => {
    setIsAuthenticating(true);
    try {
      await dispatch(checkSession()).unwrap();
    } catch (err) {
      console.error(err);
    } finally {
      setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  if (!isInitialized || isAuthenticating) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}
