import {Fragment, useState} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/20/solid';
import {TDropdownTree} from 'ui-library/_types';

interface IDropdownTreeProps {
  items: TDropdownTree[];
  selected?: TDropdownTree;
  onClickItem: (e: any) => Promise<void>;
}

const DropdownTree = ({items, selected, onClickItem}: IDropdownTreeProps) => (
  <Menu as="div" className="relative inline-block text-left">
    <div>
      <Menu.Button
        className="inline-flex w-full justify-center rounded 
          bg-primary-800 hover:bg-primary-900 px-3 py-[8px] md:px-4 md:py-[10px]
          text-base font-semibold text-white leading-none"
      >
        {items.length > 0 && (selected?.label || items[0].label)}
        <ChevronDownIcon className="-mr-1 ml-2 w-4" aria-hidden="true" />
      </Menu.Button>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className="cursor-pointer absolute z-10 mt-2 w-40 origin-top-right
          rounded bg-white border shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div className="py-1">
          {items.map((item, index) => (
            <ChildMenuItem onClickItem={onClickItem} key={index} item={item} />
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
);

export default DropdownTree;

const ChildMenuItem = ({
  item,
  onClickItem,
}: {
  item: TDropdownTree;
  onClickItem: (e: any) => Promise<void>;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Menu.Item>
      {() => (
        <div onMouseLeave={() => setOpen(false)} className="flex">
          <a
            onMouseEnter={() => setOpen(true)}
            onClick={() => onClickItem(item.value)}
            className="w-full tree-title hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm"
          >
            {item.label}
          </a>
          {item.children && item.children.length > 0 && (
            <ChevronUpIcon
              onClick={() => setOpen(false)}
              className="relative right-0 top-2.5 h-5 w-5 rotate-90"
              aria-hidden="true"
            />
          )}
          {open && item.children && item.children.length > 0 && (
            <Menu.Items
              className="absolute z-10 w-40 left-40 origin-bottom-right 
              rounded bg-white border shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {item.children.map((childItem, index) => (
                <ChildMenuItem
                  onClickItem={onClickItem}
                  key={index}
                  item={childItem}
                />
              ))}
            </Menu.Items>
          )}
        </div>
      )}
    </Menu.Item>
  );
};
