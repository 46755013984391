export enum RoleEnum {
  GLOBAL_ADMIN = 'GLOBAL_ADMIN',
  ADMIN = 'ADMIN',
  Executive = 'EXECUTIVE',
  PM = 'PM',
  NO_ACCESS = 'NO_ACCESS',
  CONSULTANT = 'CONSULTANT',
  PARTNER = 'PARTNER',
  Other = 'OTHER',
}
