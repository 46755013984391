import {CO_RELATION_TYPE} from '_enum';

export const APP_TITLE = 'Amplon Oy';

export const PROJECT_TAG_MAX = 4;
export const LIST_DEFAULT_LIMIT = 20;
export const KPI_TYPE_IRREGULAR = 1;
export const KPI_TYPE_REGULAR = 2;

export const CATEGORY_LIST = [
  {label: 'constants.categories.ltos', value: CO_RELATION_TYPE.LONG_TERM_OBJ}, // translation.json
  {label: 'constants.categories.aos', value: CO_RELATION_TYPE.ANNUAL_OBJ},
  {label: 'constants.categories.projs', value: CO_RELATION_TYPE.IMROV_OBJ},
  {label: 'constants.categories.kpis', value: CO_RELATION_TYPE.TARGET_KPI},
];

export * from './page';
export * from './x-matrix';
export * from './report';
export * from './project';
export * from './kpi';
export * from './support';
export * from './correlation';
