import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  PropsWithChildren,
} from 'react';

interface IModalContext {
  openedModals: string[];
  setOpenedModals: Dispatch<SetStateAction<string[]>>;
}

export const ModalContext = createContext<IModalContext>({
  openedModals: [],
  setOpenedModals: () => {},
});

export const ModalProvider = ({children}: PropsWithChildren) => {
  const [openedModals, setOpenedModals] = useState<string[]>([]);

  return (
    <ModalContext.Provider value={{openedModals, setOpenedModals}}>
      {children}
    </ModalContext.Provider>
  );
};
