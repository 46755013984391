import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {checkSession, loginUser, logoutUser} from 'redux/reducers/auth';
import {TAuthInitState} from 'redux/types';

const initialState: TAuthInitState = {
  isAuthenticated: false,
  isInitialized: false,
  loading: false,
  user: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateOrganizationLogo: (state, action: PayloadAction<{logo: string}>) => {
      if (state.user) {
        state.user.organization!.logo = action.payload.logo;
      }
    },
    removeAuth: state => {
      state.isAuthenticated = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginUser.pending, state => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.data.data.user;
        state.loading = false;
        state.isAuthenticated = true;
        state.isInitialized = true;
      })
      .addCase(loginUser.rejected, state => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isInitialized = true;
      })
      .addCase(logoutUser.fulfilled, state => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(checkSession.fulfilled, (state, action) => {
        state.user = action.payload.data.data;
        state.loading = false;
        state.isInitialized = true;
        state.isAuthenticated = true;
      })
      .addCase(checkSession.rejected, state => {
        state.loading = false;
        state.isAuthenticated = false;
        state.isInitialized = true;
      });
  },
});

export default slice.reducer;

export const {actions} = slice;
