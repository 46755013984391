import {SVGProps} from 'react';

const SixDotsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <circle cx={4} cy={12} r={2} />
    <circle cx={12} cy={12} r={2} />
    <circle cx={20} cy={12} r={2} />
    <circle cx={4} cy={6} r={2} />
    <circle cx={12} cy={6} r={2} />
    <circle cx={20} cy={6} r={2} />
  </svg>
);

export default SixDotsIcon;
