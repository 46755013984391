import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getData, getXMatrixSettings} from 'redux/reducers/x-matrix';
import {store} from 'redux/store';
import {TXMatrixInitState} from 'redux/types';
import {xmatrixFiller} from 'utils/x-matrix-utils';
import {X_MATRIX_ROW_MIN_AMOUNTS} from '_constant';
import {XMatrixRow, XMAtrixUserRow} from '_types/types';
import {IXMatrixSettings} from '../../interfaces/organization';

const initialState: TXMatrixInitState = {
  IP_ITEMS: [],
  AO_ITEMS: [],
  KPI_ITEMS: [],
  LTO_ITEMS: [],
  USER_ITEMS: [],
  loaded: false,
  activeCoRelation: undefined,
  activeCoRelationIds: new Set(),
  AO_LABEL_REF: [],
  KPI_LABEL_REF: [],
  centerText: null,
  weakCorrelationEnabled: false,
  xMatrixPlayBackEnabled: true,
};

const slice = createSlice({
  name: 'x-matrix',
  initialState,
  reducers: {
    setActive: {
      reducer: (state, action: PayloadAction<any>) => {
        state.activeCoRelation = action.payload.data;
        state.activeCoRelationIds = action.payload.activeIds;
      },
      prepare: (data: XMatrixRow) => {
        const activeIds = new Set<number>();
        const xmatrix = store.getState()?.xmatrix;

        if (xmatrix?.activeCoRelation?.id === data.id) {
          return {payload: {data: undefined, activeIds}};
        }
        findCoRelationWithType(data, activeIds);

        return {payload: {data, activeIds}};
      },
    },
    setActiveCoRelByUser: {
      reducer: (state, action: PayloadAction<any>) => {
        state.activeCoRelation = action.payload.data;
        state.activeCoRelationIds = action.payload.activeIds;
      },
      prepare: (data: XMAtrixUserRow) => {
        const activeIds = new Set<number>();
        const xmatrix = store.getState()?.xmatrix;

        if (xmatrix?.activeCoRelation?.id === data.id) {
          return {payload: {data: undefined, activeIds}};
        }
        findCoRelationFromUser(data, activeIds);

        return {payload: {data, activeIds}};
      },
    },
    addAOLabelRef: {
      reducer: (state: TXMatrixInitState, action: PayloadAction<any>) => {
        state.AO_LABEL_REF = action.payload;
      },
      prepare: (data: any) => {
        const AO_LABEL_REF: any[] = store.getState()?.xmatrix.AO_LABEL_REF;
        const latestAO_LABEL_REF = [
          ...AO_LABEL_REF.filter(item => item.id !== data.id),
          ...[data],
        ];

        return {payload: latestAO_LABEL_REF};
      },
    },
    addKPILabelRef: {
      reducer: (state: TXMatrixInitState, action: PayloadAction<any>) => {
        state.KPI_LABEL_REF = action.payload;
      },
      prepare: (data: any) => {
        const KPI_LABEL_REF: any[] = store.getState()?.xmatrix.KPI_LABEL_REF;
        const latestKPI_LABEL_REF = [
          ...KPI_LABEL_REF.filter(item => item.id !== data.id),
          ...[data],
        ];

        return {payload: latestKPI_LABEL_REF};
      },
    },
    setNonActive: (state: TXMatrixInitState) => {
      state.activeCoRelation = undefined;
      state.activeCoRelationIds = new Set();
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getXMatrixSettings.fulfilled,
        (state, action: PayloadAction<IXMatrixSettings | null>) => {
          state.centerText = action.payload;
          state.weakCorrelationEnabled =
            action.payload?.WeakCorrelationEnabled ?? false;
          state.xMatrixPlayBackEnabled =
            action.payload?.XMatrixPlayBackEnabled ?? true;
        }
      )
      .addCase(getData.pending, () => {})
      .addCase(getData.fulfilled, (state, action: any) => {
        state.IP_ITEMS = xmatrixFiller(
          [...action.payload.data.data.ProjectActivities],
          X_MATRIX_ROW_MIN_AMOUNTS.improvement_projects
        );
        state.AO_ITEMS = xmatrixFiller(
          [...action.payload.data.data.AnnualObjectives],
          X_MATRIX_ROW_MIN_AMOUNTS.annual_objectives
        );
        state.KPI_ITEMS = xmatrixFiller(
          [...action.payload.data.data.TargetAndKPIs],
          X_MATRIX_ROW_MIN_AMOUNTS.targets_and_kpis,
          'end'
        );
        state.LTO_ITEMS = xmatrixFiller(
          [...action.payload.data.data.LongTermObjectives],
          X_MATRIX_ROW_MIN_AMOUNTS.long_term_objectives,
          'end'
        );
        // change corelation string ids into numbers type
        const ProjectOwners = [
          ...action.payload.data.data.ProjectOwners.map((item: any) => {
            item.correlations = item.correlations.map((id: string) => +id);

            return item;
          }),
        ];

        state.USER_ITEMS = xmatrixFiller(
          ProjectOwners,
          X_MATRIX_ROW_MIN_AMOUNTS.end_users,
          'end'
        );
        state.loaded = true;
      })
      .addCase(getData.rejected, state => ({
        IP_ITEMS: [],
        AO_ITEMS: [],
        KPI_ITEMS: [],
        LTO_ITEMS: [],
        USER_ITEMS: [],
        loaded: false,
        activeCoRelation: undefined,
        activeCoRelationIds: new Set(),
        AO_LABEL_REF: [],
        KPI_LABEL_REF: [],
        centerText: state.centerText,
        weakCorrelationEnabled: false,
        xMatrixPlayBackEnabled: true,
      }));
  },
});

export default slice.reducer;

export const {
  setActive,
  setActiveCoRelByUser,
  addAOLabelRef,
  addKPILabelRef,
  setNonActive,
} = slice.actions;

const findCoRelationFromUser = (
  currenrItem: XMAtrixUserRow,
  activeIds: Set<number>
) => {
  const xmatrix = store.getState()?.xmatrix;

  currenrItem.correlations.forEach(id => {
    xmatrix.IP_ITEMS.filter(item => id === item.id).forEach(item => {
      activeIds.add(item.id);
      findImprProjectCoRelation(item, activeIds);
    });
  });
};

const findCoRelationWithType = (
  currenrItem: XMatrixRow,
  activeIds: Set<number>
) => {
  if (currenrItem.correlation_type_id === 1) {
    findLTOCoRelation(currenrItem, activeIds);
  } else if (currenrItem.correlation_type_id === 2) {
    findAnnualObjCoRelation(currenrItem, activeIds);
  } else if (currenrItem.correlation_type_id === 3) {
    findImprProjectCoRelation(currenrItem, activeIds);
  } else if (currenrItem.correlation_type_id === 4) {
    findKPICoRelation(currenrItem, activeIds);
  }
};

const findKPICoRelation = (currenrItem: XMatrixRow, activeIds: Set<number>) => {
  const xmatrix = store.getState()?.xmatrix;

  xmatrix.IP_ITEMS.filter(ipItem =>
    ipItem?.pairedCorrelations?.some(
      (paired: any) => paired.correlation_to === currenrItem.id
    )
  ).forEach(ipItem => {
    activeIds.add(ipItem.id);

    xmatrix.AO_ITEMS.filter(aoItem =>
      aoItem?.pairedCorrelations?.some(
        (paired: any) => paired.correlation_to === ipItem.id
      )
    ).forEach(aoItem => {
      activeIds.add(aoItem.id);

      xmatrix.LTO_ITEMS.filter(ltoItem =>
        ltoItem?.pairedCorrelations?.some(
          (paired: any) => paired.correlation_to === aoItem.id
        )
      ).forEach(ltoItem => {
        activeIds.add(ltoItem.id);
      });
    });
  });

  xmatrix.USER_ITEMS.filter(userItem =>
    userItem?.correlations?.some((id: number) => id === currenrItem.id)
  ).forEach(kpiItem => {
    activeIds.add(kpiItem.id);
  });
};

const findImprProjectCoRelation = (
  currenrItem: XMatrixRow,
  activeIds: Set<number>
) => {
  const xmatrix = store.getState()?.xmatrix;

  xmatrix.AO_ITEMS.filter(aoItem =>
    aoItem?.pairedCorrelations?.some(
      (paired: any) => paired.correlation_to === currenrItem.id
    )
  ).forEach(aoItem => {
    activeIds.add(aoItem.id);

    xmatrix.LTO_ITEMS.filter(ltoItem =>
      ltoItem?.pairedCorrelations?.some(
        (paired: any) => paired.correlation_to === aoItem.id
      )
    ).forEach(ltoItem => {
      activeIds.add(ltoItem.id);
    });
  });

  xmatrix.KPI_ITEMS.filter(kpiItem =>
    currenrItem?.pairedCorrelations?.some(
      (paired: any) => paired.correlation_to === kpiItem.id
    )
  ).forEach(kpiItem => {
    activeIds.add(kpiItem.id);
  });

  xmatrix.USER_ITEMS.filter(userItem =>
    userItem?.correlations?.some((id: number) => id === currenrItem.id)
  ).forEach(kpiItem => {
    activeIds.add(kpiItem.id);
  });
};

const findLTOCoRelation = (currenrItem: XMatrixRow, activeIds: Set<number>) => {
  const xmatrix = store.getState()?.xmatrix;

  xmatrix.AO_ITEMS.filter(item =>
    currenrItem?.pairedCorrelations?.some(
      (paired: any) => paired.correlation_to === item.id
    )
  ).forEach(item => {
    activeIds.add(item.id);

    xmatrix.IP_ITEMS.filter(ipItem =>
      item?.pairedCorrelations?.some(
        (paired: any) => paired.correlation_to === ipItem.id
      )
    ).forEach(ipItem => {
      activeIds.add(ipItem.id);

      xmatrix.KPI_ITEMS.filter(kpiItem =>
        ipItem?.pairedCorrelations?.some(
          (paired: any) => paired.correlation_to === kpiItem.id
        )
      ).forEach(kpiItem => {
        activeIds.add(kpiItem.id);
      });

      xmatrix.USER_ITEMS.filter(userItem =>
        userItem?.correlations?.some((id: number) => id === ipItem.id)
      ).forEach(kpiItem => {
        activeIds.add(kpiItem.id);
      });
    });
  });
};

const findAnnualObjCoRelation = (
  currenrItem: XMatrixRow,
  activeIds: Set<number>
) => {
  const xmatrix = store.getState()?.xmatrix;

  xmatrix.LTO_ITEMS.filter(ltoItem =>
    ltoItem?.pairedCorrelations?.some(
      (paired: any) => paired.correlation_to === currenrItem.id
    )
  ).forEach(ltoItem => {
    activeIds.add(ltoItem.id);
  });

  xmatrix.IP_ITEMS.filter(ipItem =>
    currenrItem?.pairedCorrelations?.some(
      (paired: any) => paired.correlation_to === ipItem.id
    )
  ).forEach(ipItem => {
    activeIds.add(ipItem.id);
    xmatrix.KPI_ITEMS.filter(kpiItem =>
      ipItem?.pairedCorrelations?.some(
        (paired: any) => paired.correlation_to === kpiItem.id
      )
    ).forEach(kpiItem => {
      activeIds.add(kpiItem.id);
    });
    xmatrix.USER_ITEMS.filter(userItem =>
      userItem?.correlations?.some((id: number) => id === ipItem.id)
    ).forEach(kpiItem => {
      activeIds.add(kpiItem.id);
    });
  });
};
