"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/_constant/index.ts
var constant_exports = {};
__export(constant_exports, {
  ERROR_COLOR_CODE: () => ERROR_COLOR_CODE,
  GRAY_COLOR_CODE: () => GRAY_COLOR_CODE,
  PRIMARY_COLOR_CODE: () => PRIMARY_COLOR_CODE,
  SUCCESS_COLOR_CODE: () => SUCCESS_COLOR_CODE,
  WARNING_COLOR_CODE: () => WARNING_COLOR_CODE
});
module.exports = __toCommonJS(constant_exports);
var PRIMARY_COLOR_CODE = {
  25: "#e0ffe1",
  50: "#b9ffbb",
  100: "#b6e1bc",
  200: "#1ab394",
  300: "#6CE9A6",
  400: "#93d39c",
  500: "#64c171",
  600: "#45ad54",
  700: "#027A48",
  800: "#005e02",
  900: "#054F31"
};
var GRAY_COLOR_CODE = {
  25: "#FCFCFD",
  50: "#F9FAFB",
  100: "#F2F4F7",
  200: "#EAECF0",
  300: "#D0D5DD",
  400: "#98A2B3",
  500: "#667085",
  600: "#475467",
  700: "#344054",
  800: "#1D2939",
  900: "#101828"
};
var WARNING_COLOR_CODE = {
  25: "#FFFCF5",
  50: "#FFFAEB",
  100: "#FEF0C7",
  200: "#FEDF89",
  300: "#FEC84B",
  400: "#FDB022",
  500: "#F79009",
  600: "#DC6803",
  700: "#027A48",
  800: "#93370D",
  900: "#7A2E0E"
};
var ERROR_COLOR_CODE = {
  25: "#FFFBFA",
  50: "#FEF3F2",
  100: "#FEE4E2",
  200: "#FECDCA",
  300: "#FDA29B",
  400: "#F97066",
  500: "#ff0000",
  600: "#D92D20",
  700: "#B42318",
  800: "#912018",
  900: "#7A271A"
};
var SUCCESS_COLOR_CODE = {
  25: "#FCFAFF",
  50: "#F9F5FF",
  100: "#F4EBFF",
  200: "#E9D7FE",
  300: "#D6BBFB",
  400: "#B692F6",
  500: "#9E77ED",
  600: "#7F56D9",
  700: "#6941C6",
  800: "#53389E",
  900: "#42307D"
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ERROR_COLOR_CODE,
  GRAY_COLOR_CODE,
  PRIMARY_COLOR_CODE,
  SUCCESS_COLOR_CODE,
  WARNING_COLOR_CODE
});
