import LoadingScreen from 'components/LoadingScreen';
import {useEffect, ReactNode, useState} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import {getOrgUnits} from 'redux/reducers/org-units';
import {getXMatrixSettings} from 'redux/reducers/x-matrix';
import {RoleEnum} from '_enum';
import {checkSession} from '../redux/reducers/auth';
import {RootState} from '../redux/rootReducer';
import {dispatch} from '../redux/store';
import {stripTrailingSlash} from '../utils/helper';

interface IAuthGuard {
  children: ReactNode;
}

export default function AuthGuard({children}: IAuthGuard) {
  const {isAuthenticated, isInitialized, user} = useSelector(
    (state: RootState) => state.auth
  );
  const {pathname} = useLocation();
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  const getSession = async () => {
    setIsAuthenticating(true);
    try {
      await dispatch(checkSession()).unwrap();
      await dispatch(getOrgUnits()).unwrap();
      await dispatch(getXMatrixSettings()).unwrap();
    } catch (err) {
      console.error(err);
    } finally {
      setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  if (!isInitialized || isAuthenticating) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    const redirectUrl = btoa(
      `${stripTrailingSlash(window.location.origin)}${
        requestedLocation || '/dashboard'
      }`
    );

    return <Navigate to={`/auth/login?redirectUrl=${redirectUrl}`} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);

    return <Navigate to={requestedLocation} />;
  }

  if (user?.role === RoleEnum.PARTNER && pathname !== '/project-management') {
    return <Navigate to="/project-management" />;
  }

  return <>{children}</>;
}
