"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/_enum/index.ts
var enum_exports = {};
__export(enum_exports, {
  EButtonColorVariant: () => EButtonColorVariant,
  EColorVariant: () => EColorVariant,
  ESizeVariant: () => ESizeVariant,
  ETypographyVarient: () => ETypographyVarient
});
module.exports = __toCommonJS(enum_exports);
var ESizeVariant = /* @__PURE__ */ ((ESizeVariant2) => {
  ESizeVariant2["SM"] = "sm";
  ESizeVariant2["MD"] = "md";
  ESizeVariant2["LG"] = "lg";
  ESizeVariant2["XL"] = "xl";
  ESizeVariant2["DXL"] = "2xl";
  return ESizeVariant2;
})(ESizeVariant || {});
var EColorVariant = /* @__PURE__ */ ((EColorVariant2) => {
  EColorVariant2["PRIMARY"] = "primary";
  EColorVariant2["ERROR"] = "error";
  EColorVariant2["WARNING"] = "warning";
  EColorVariant2["SUCCESS"] = "success";
  return EColorVariant2;
})(EColorVariant || {});
var EButtonColorVariant = /* @__PURE__ */ ((EButtonColorVariant2) => {
  EButtonColorVariant2["OUTLINE_PRIMARY"] = "outline_primary";
  EButtonColorVariant2["OUTLINE_ERROR"] = "outline_error";
  EButtonColorVariant2["OUTLINE_WARNING"] = "outline_warning";
  EButtonColorVariant2["OUTLINE_SUCCESS"] = "outline_success";
  return EButtonColorVariant2;
})(EButtonColorVariant || {});
var ETypographyVarient = /* @__PURE__ */ ((ETypographyVarient2) => {
  ETypographyVarient2["SM"] = "sm";
  ETypographyVarient2["MD"] = "md";
  ETypographyVarient2["LG"] = "lg";
  ETypographyVarient2["XL"] = "xl";
  ETypographyVarient2["DXL"] = "2xl";
  ETypographyVarient2["H1"] = "h1";
  ETypographyVarient2["H2"] = "h2";
  ETypographyVarient2["H3"] = "h3";
  ETypographyVarient2["H4"] = "h4";
  ETypographyVarient2["H5"] = "h5";
  ETypographyVarient2["H6"] = "h6";
  return ETypographyVarient2;
})(ETypographyVarient || {});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  EButtonColorVariant,
  EColorVariant,
  ESizeVariant,
  ETypographyVarient
});
