import {TXMatrixInitState} from 'redux/types';
import {CO_RELATION_TYPE} from '_enum';
import {XMatrixRow, XMAtrixUserRow} from '_types/x-matrix';

export const xmatrixFiller = (items: any[], min: number, where?: string) => {
  let filler = -999;

  if (items.length >= min) return items;
  do {
    where === 'end'
      ? items.push({id: --filler, label: '\u00A0'})
      : items.unshift({id: --filler, label: '\u00A0'});
  } while (items.length < min);

  return items;
};

export const coRelationBoxCheck = (
  topRelationItem: XMatrixRow,
  activeCoRelation?: XMatrixRow | XMAtrixUserRow,
  activeCoRelationIds: Set<number> = new Set<number>(),
  item?: XMatrixRow,
  userItem?: XMAtrixUserRow
) =>
  topRelationItem?.id === activeCoRelation?.id ||
  activeCoRelationIds.has(topRelationItem.id) ||
  (item &&
    (item?.id === activeCoRelation?.id || activeCoRelationIds.has(item.id))) ||
  (userItem &&
    (userItem?.id === activeCoRelation?.id ||
      activeCoRelationIds.has(userItem.id)));

export const isCoRelationBoxRelated = (
  item: XMatrixRow,
  topRelationItem: XMatrixRow
) => {
  if (
    item.correlation_type_id === 2 &&
    topRelationItem.correlation_type_id === 3
  ) {
    return item?.pairedCorrelations?.find(
      (e: any) => e.correlation_to === topRelationItem.id
    );
  } else {
    return topRelationItem?.pairedCorrelations?.find(
      (e: any) => e.correlation_to === item.id
    );
  }
};

export const isCoRelationBoxRelatedUser = (
  userItem: XMAtrixUserRow,
  topRelationItem: XMatrixRow
) => userItem?.correlations?.find(id => id === topRelationItem.id);

export const sortColrelations = (
  correlations: any[],
  oldIndex: number,
  newIndex: number
) =>
  correlations.map(item => {
    if (item.display_after === oldIndex) {
      return {
        ...item,
        display_after: newIndex,
      };
    } else if (
      oldIndex < newIndex &&
      item.display_after <= newIndex &&
      !(item.display_after < oldIndex)
    ) {
      return {
        ...item,
        display_after: item.display_after - 1,
      };
    } else if (
      oldIndex > newIndex &&
      item.display_after >= newIndex &&
      !(item.display_after > oldIndex)
    ) {
      return {
        ...item,
        display_after: item.display_after + 1,
      };
    }

    return item;
  });

export const getCoRelationBoxWidth = (
  item: XMatrixRow,
  xmatrix: TXMatrixInitState
) => {
  if (item?.correlation_type_id === CO_RELATION_TYPE.ANNUAL_OBJ) {
    return xmatrix.AO_LABEL_REF.find(refItem => refItem.id === item.id)?.width;
  }
  if (item?.correlation_type_id === CO_RELATION_TYPE.TARGET_KPI) {
    return xmatrix.KPI_LABEL_REF.find(refItem => refItem.id === item.id)?.width;
  }

  return false;
};
