import {combineReducers} from 'redux';
// slices
import authReducer from './slices/auth';
import breadcrumb from './slices/breadcrumb';
import orgUnits from './slices/org-units';
import xmatrix from './slices/x-matrix';
import modalState from './slices/modal-state';

const rootReducer = combineReducers({
  auth: authReducer,
  xmatrix: xmatrix,
  breadcrumb,
  orgUnits,
  modalState,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
