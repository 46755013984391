import {Bars3Icon} from '@heroicons/react/20/solid';
import {Link} from 'react-router-dom';
import AccountDropdownMenu from './AccountDropdownMenu';
import {Button, Typography, BreadCrumb} from 'ui-library';
import {EColorVariant, ETypographyVarient} from 'ui-library/_enum';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducer';
import DropdownTree from 'components/DropdownTree';
import {
  makeParentChildUnits,
  convertUnitsToTreeDropdOwnUnits,
} from 'utils/organization-utils';
import {dispatch} from 'redux/store';
import {changeActiveOrgUnit} from 'redux/reducers/org-units';
import {checkSession} from 'redux/reducers/auth';
import {toggleOrgChangeLoading} from 'redux/slices/org-units';
import {RoleEnum} from '_enum';
import {useTranslation} from 'react-i18next';

export default function Header({toggleSidebar}: {toggleSidebar: () => void}) {
  const {breadcrumb, orgUnits, auth} = useSelector((state: RootState) => state);

  const {t} = useTranslation();

  const navIconClassNames =
    'inline-flex items-center ' +
    'justify-center rounded-full bg-white p-2 text-gray-600 hover:bg-gray-100 ' +
    'hover:text-gray-800 focus:outline-none cursor-pointer';

  const changeOrganizationUnit = async (id: number) => {
    try {
      if (auth.user?.organization_unit?.id !== id) {
        dispatch(toggleOrgChangeLoading(true));
        await dispatch(changeActiveOrgUnit(id));
        await dispatch(checkSession()).unwrap();
      }
    } catch (error) {
      console.error('error:', error);
    } finally {
      dispatch(toggleOrgChangeLoading(false));
    }
  };

  return (
    <header
      className="bg-white relative flex flex-0 items-center
      w-full h-16 px-2 md:px-6 z-49 shadow dark:border-b bg-card print:hidden"
    >
      <nav className="w-full flex justify-between">
        <div className="flex space-x-2">
          <button
            type="button"
            onClick={() => toggleSidebar()}
            className={navIconClassNames}
          >
            <span className="sr-only">{t('header.open_menu')}</span>
            <Bars3Icon className="h-6 w-6 font-bold" aria-hidden="true" />
          </button>
          <ul className="flex justify-center items-center gap-x-2 md:gap-x-6">
            <li className="hidden sm:block">
              <Typography varient={ETypographyVarient.H4}>
                {auth?.user?.organization?.name || ''}
              </Typography>
            </li>
            <li>
              <DropdownTree
                onClickItem={changeOrganizationUnit}
                selected={{
                  label: auth?.user?.organization_unit?.unit_name,
                  value: auth?.user?.organization_unit?.id,
                }}
                items={convertUnitsToTreeDropdOwnUnits(
                  makeParentChildUnits(
                    orgUnits.ORG_UNITS.filter(
                      unit => unit.role !== RoleEnum.NO_ACCESS
                    )
                  )
                )}
              />
            </li>
          </ul>
        </div>
        <ul className="flex justify-center items-center gap-x-2 md:gap-x-6">
          <li className="hidden md:block">
            <BreadCrumb items={breadcrumb?.items} />
          </li>
          <li className="block sm:hidden">
            <Link to="/project-management">
              <Button text={t('header.tasks')} color={EColorVariant.PRIMARY} />
            </Link>
          </li>
          <AccountDropdownMenu />
        </ul>
      </nav>
    </header>
  );
}
