type UserInitials = {
  size?: 'sm' | 'md' | 'lg';
  initials: string;
};

const UserInitials = ({size, initials}: UserInitials) => {
  let sizeClass: string;

  switch (size) {
    case 'lg':
      sizeClass = '';
      break;
    case 'md':
      sizeClass = 'h-9 w-9 text-sm';
      break;
    default:
      sizeClass = ' h-7 w-7 text-[10px]';
      break;
  }

  return (
    <span
      className={
        'rounded-full tracking-wider inline-flex items-center justify-center' +
        ' text-bolder bg-primary-900 text-white ' +
        `${sizeClass}`
      }
    >
      {initials}
    </span>
  );
};

export default UserInitials;
