import {ArrowLeftIcon} from '@heroicons/react/24/outline';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const ForbiddenPage = () => {
  const navigate = useNavigate();

  const {t} = useTranslation();

  return (
    <div className="w-full min-h-screen flex items-center justify-center mx-auto bg-[#F4F5F7]">
      <div className="bg-white w-full max-w-5xl min-h-[80vh] p-12 m-auto">
        <p className="font-semibold text-md text-primary-700 mb-3">
          {t('forbidden.403_error')}
        </p>
        <div className="mb-6">
          <h1 className="text-3xl font-semibold text-gray-900">
            {t('forbidden.forbidden_access')}
          </h1>
          <p className="text-gray-600 mt-2">{t('forbidden.forbidden_text')}</p>
        </div>
        <div className="flex flex-wrap gap-4 w-full my-8">
          <a
            onClick={() => navigate(-1)}
            className="cursor-pointer text-gray-700 font-semibold text-md flex gap-3 w-full items-center justify-center
             bg-white border border-gray-300 rounded-md  shadow-sm px-8 py-1.5 sm:w-auto hover:bg-gray-100"
          >
            <ArrowLeftIcon className="w-5 font-bold h-5" />
            <p>{t('forbidden.go_back')}</p>
          </a>
          <Link
            to="/"
            className="cursor-pointer text-white font-semibold text-md flex gap-3 w-full items-center justify-center
             bg-primary-600 border border-gray-300 rounded-md shadow-sm px-8 py-1.5  sm:w-auto hover:bg-primary-700"
          >
            {t('forbidden.take_me_home')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForbiddenPage;
