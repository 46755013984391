import {TDropdownOption, TDropdownTree} from 'ui-library/_types';
import {TOrgUnit} from '_types/organization';

export const makeParentChildUnits = (units: TOrgUnit[]): TOrgUnit[] => {
  const addedUnitIds: number[] = [];
  const parents = units
    .filter(unit => !unit.parent_organization_unit_id)
    .map(unit => {
      addedUnitIds.push(unit.id);
      const unitRow = {
        ...unit,
        children: getUnitChildren(units, unit, addedUnitIds),
      };

      return unitRow;
    });

  return parents;
};

export const getUnitChildren = (
  units: TOrgUnit[],
  unit: TOrgUnit,
  addedUnitIds: number[]
) => {
  const remainUnits = units.filter(unit => !addedUnitIds.includes(unit.id));

  return remainUnits
    .filter(childUnit => childUnit.parent_organization_unit_id === unit.id)
    .map(childUnit => {
      addedUnitIds.push(childUnit.id);
      const unitRow: TOrgUnit = {
        ...childUnit,
        children: getUnitChildren(remainUnits, childUnit, addedUnitIds),
      };

      return unitRow;
    });
};

export const getParentUnit = (units: TOrgUnit[], childId: number) => {
  const child = units.find(unit => unit.id === childId);

  if (!child) {
    return null;
  }
  const parent = units.find(
    unit => unit.id === child.parent_organization_unit_id
  );

  return parent || null;
};

export const convertUnitsToTreeDropdOwnUnits = (
  units: TOrgUnit[]
): TDropdownTree[] =>
  units.map(
    unit =>
      ({
        label: unit.unit_name,
        value: unit.id,
        children: unit.children
          ? convertUnitsToTreeDropdOwnUnits(unit.children)
          : [],
      } as TDropdownTree)
  );

export const convertUnitsToDropDownUnits = (
  units: TOrgUnit[],
  empty = false
): TDropdownOption[] => {
  const data: TDropdownOption[] = [];

  if (empty) {
    data.push({label: 'Select', value: ''});
  }

  units.map(unit => {
    data.push({
      label: unit.unit_name,
      value: unit.id,
    });
  });

  return data;
};
