import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {changeActiveOrgUnit, getOrgUnits} from 'redux/reducers/org-units';
import {TOrgUnitsInitState} from 'redux/types';

const initialState: TOrgUnitsInitState = {
  ORG_UNITS: [],
  loading: false,
};

const slice = createSlice({
  name: 'org_units',
  initialState,
  reducers: {
    toggleOrgChangeLoading: (
      state: TOrgUnitsInitState,
      action: PayloadAction<any>
    ) => {
      state.loading = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getOrgUnits.fulfilled, (state, action: PayloadAction<any>) => {
        state.ORG_UNITS = action.payload.data.data;
      })
      .addCase(changeActiveOrgUnit.pending, () => {})
      .addCase(changeActiveOrgUnit.fulfilled, () => {});
  },
});

export default slice.reducer;
export const {actions} = slice;
export const {toggleOrgChangeLoading} = slice.actions;
