import {ProjectManagementTabEnum} from '_enum';

export const PROJECT_MANAGEMENT = [
  {
    label: 'PM Reports',
    value: ProjectManagementTabEnum.PM_REPORTS,
  },
  {
    label: 'Project overview',
    value: ProjectManagementTabEnum.PROJECT_OVERVIEW,
  },
  {label: 'Monthly KPIs', value: ProjectManagementTabEnum.MONTHLY_KPIS},
  {
    label: 'Objective Status',
    value: ProjectManagementTabEnum.OBJECTIVE_STATUS,
  },
  {
    label: 'Backlog',
    value: ProjectManagementTabEnum.BACKLOG,
  },
];

export const MONTHS_OPTIONS = [
  {
    label: '3 months',
    value: 3,
  },
  {
    label: '6 months',
    value: 6,
  },
  {
    label: '12 months',
    value: 12,
  },
  {
    label: '15 months',
    value: 15,
  },
];
