export const requestErrorHandler = (err: any) => {
  const statusCode = err.response?.status || 500;
  const errorMeassage =
    err.response?.data?.message ||
    err.response?.data?.error ||
    err.response?.error ||
    err?.message ||
    'Unknown request error!';
  const errors = err.response?.data.errors;
  let formattedErrors: {message: string; field?: string}[];

  if (errors && typeof errors !== 'string') {
    formattedErrors = Object.keys(errors).map(key => ({
      message: errors[key][0],
      field: key,
    }));
  } else {
    formattedErrors = [
      {
        message:
          err.response?.data?.message ||
          err.response?.data?.error ||
          err.response?.error ||
          err?.message ||
          'Unknown request error!',
      },
    ];
  }

  return {statusCode, errorMessages: formattedErrors, errorMeassage};
};
